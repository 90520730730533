import { Component } from '@angular/core';
import { of } from 'rxjs';
import { DatasetInputItem } from 'src/app/models/dataset-input-item';
import { DatasetInputsService } from 'src/app/services/dataset-inputs.service';

@Component({
  selector: 'app-inputs',
  templateUrl: './inputs.component.html',
  styleUrls: ['./inputs.component.sass']
})
export class InputsComponent {

  get isNoSearchResult(): boolean { return this.datasetsService.isNoSearchResult; }

  items$ = of<DatasetInputItem[]>([]);
  total$ = of<number>();

  constructor(public datasetsService: DatasetInputsService) {
    this.items$ = this.datasetsService.items$;
    this.total$ = this.datasetsService.total$;
  }

}
