import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, firstValueFrom, of } from 'rxjs';
import { DatasetInputItem } from 'src/app/models/dataset-input-item';
import { SharedService } from 'src/app/services/shared.service';
import { CalculationService } from 'src/app/services/calculation-service';
import { DatasetInputsService } from 'src/app/services/dataset-inputs.service';
import { ErrorMessagingService } from '../shared/error-messaging.service';
import { AuthorisationService } from 'wre-authlib';
import { UserPermissions } from 'src/environments/environment-base';
import { CalculationRequest } from 'src/app/models/calculation-request';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.sass']
})
export class ConfigurationComponent implements OnInit {

  items$ = of<DatasetInputItem[]>([]);
  total$ = of<number>();
  activeTab = 1;

  public form: UntypedFormGroup;

  isLoading = true;

  get useSecondary() {
    return this.form.get('useSecondary').value === true;
  }

  private canExport$ = new BehaviorSubject<boolean>(true);

  get canExport() {
    return this.canExport$.asObservable();
  }

  constructor(
    public datasetsService: DatasetInputsService,
    public calculationResultsService: CalculationService,
    private fb: UntypedFormBuilder,
    public sharedService: SharedService,
    private errorMessagingService: ErrorMessagingService,
    private authService: AuthorisationService
  ) {
    this.prepareForm();
    this.setSubscriptions();
  }

  private async checkPermissions() {
    const permissionList = await firstValueFrom(this.authService.getUserApplicationRolesWithPermissions());
    this.canExport$.next(permissionList.includes(UserPermissions.export));
  }

  private prepareForm() {
    this.form = this.fb.group({
      seed: ['0', [Validators.required, Validators.min(0), Validators.max(9999999)]],
      simulation: ['1', [Validators.required, Validators.min(1), Validators.max(9999999)]],
      mean: ['1', [Validators.required, Validators.min(1), Validators.max(9999999)]],
      cv: ['0', [Validators.required, Validators.min(0), Validators.max(9999999)]],
      useSecondary: [true]
    });
  }

  private setSubscriptions() {
    this.sharedService.calculationCompleted.subscribe(() => {
      this.activeTab = 2; // Output tab
    });

    this.form.get('useSecondary').valueChanges.subscribe((x) => {
      if (x === false) {
        this.form.get('cv').setValue(0);
        this.form.get('cv').disable();
        this.form.get('mean').setValue(1);
        this.form.get('mean').disable();
      } else {
        this.form.get('cv').enable();
        this.form.get('mean').enable();
      }
    });
  }

  async ngOnInit() {
    this.checkPermissions();

    this.items$ = this.datasetsService.items$;
    this.total$ = this.datasetsService.total$;

    // Set values from upload response
    this.calculationResultsService.setResults(this.sharedService.uploadResponse.sims);
    this.setFormData();
    this.isLoading = false;
  }

  private setFormData() {
    const settings = this.sharedService.uploadResponse.settings;
    this.form.get('seed').setValue(settings.find(s => s.parameter === 'seed').value);
    this.form.get('simulation').setValue(settings.find(s => s.parameter === 'n').value);
    this.form.get('mean').setValue(settings.find(s => s.parameter === 'mean').value);
    this.form.get('cv').setValue(settings.find(s => s.parameter === 'cv').value);
    const useSecVal = settings.find(s => s.parameter === 'uncertainty').value;
    this.form.get('useSecondary').setValue(useSecVal === 1 ? true : false);
  }


  runCalculation() {
    console.log('In ConfigurationComponent:runCalculation:', this.sharedService.request.files);

    this.errorMessagingService.clearMessage();
    this.isLoading = true;
    this.form.markAsPristine();

    this.setConfigurationInputs();
    this.calculationResultsService.runCalculation().then(r => {
      if (!r) {
        this.form.markAsTouched(); // To enable rerun of the failed requests
      }
      this.isLoading = false;
    });
  }

  export(type: string) {
    if (!this.canExport$.getValue()) { return; }
    this.setConfigurationInputs();
    switch (type) {
      case 'iFM': {
        this.isLoading = true;
        this.sharedService.downloadiFM().finally(() => this.isLoading = false);
        break;
      }
      case 'Output': {
        this.isLoading = true;
        this.sharedService.downloadOutput().finally(() => this.isLoading = false);
        break;
      }
      default: {  return; }
    }
  }

  private setConfigurationInputs() {
    this.sharedService.request.seed = this.form.get('seed').value;
    this.sharedService.request.simulations = this.form.get('simulation').value;
    this.sharedService.request.mean = this.form.get('mean').value;
    this.sharedService.request.cv = this.form.get('cv').value;
    this.sharedService.request.uncertainty = this.form.get('useSecondary').value;
  }

  get runButtonDisabled() {
    return this.isLoading || this.form.invalid || this.form.pristine;
  }

  goBack(){
    this.sharedService.request = new CalculationRequest();
    this.sharedService.uploadDataset.emit();
  }
}
