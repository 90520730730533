import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CalculationResponse } from '../models/calculation-respose';

import { DatasetInputItem } from '../models/dataset-input-item';
import { BaseListService } from './base-list.service';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})

export class DatasetInputsService extends BaseListService<DatasetInputItem> {
  inputsResult = new BehaviorSubject<DatasetInputItem[]>(null);

  get resultItems() {
    return this.inputsResult.asObservable();
  }

  constructor(private http: HttpClient, private sharedService: SharedService) {
    super();
  }

  matches(item: DatasetInputItem, term: string) {
    const lowerTerm = term.toLowerCase();
    return item['Insured ID']?.toString().includes(lowerTerm) ||
      item['Business Unit']?.toLowerCase().includes(lowerTerm) ||
      item['Policy Limit']?.toString().includes(lowerTerm) ||
      item['Participation']?.toString().includes(lowerTerm) ||
      item['Attachment']?.toString().includes(lowerTerm) ||
      item['Policy Count']?.toString().includes(lowerTerm) ||
      item['Premium']?.toString().includes(lowerTerm) ||
      item['Region']?.toLowerCase().includes(lowerTerm) ||
      item['Exposure Category']?.toLowerCase().includes(lowerTerm) ||
      item['Size']?.toLowerCase().includes(lowerTerm);
  }

  async getData(file: File): Promise<boolean> {
    let response = null;
    try {
      const apiUrl = `${environment.entailRApiUrl}sims`;

      const formData: FormData = new FormData();
      formData.append('name', file, file.name);

      const someheaders = new HttpHeaders()
        .append('Accept', 'application/json')
        .append('noretry', 'true');

      response = this.http.post<CalculationResponse>(apiUrl, formData, {
        headers: someheaders,
        observe: 'response'
      });

      const result = await firstValueFrom(response);

      if (!result) {
        return Promise.resolve(false);
      }

      console.log('getData:', result);

      this.inputsResult.next(result['body'].policies);
      this.loadData();

      // Set file id for re-run requests
      this.sharedService.request.filename = result['body'].file[0];
      this.sharedService.uploadResponse = result['body'];
    } catch (error) {
      console.warn(error);
      return Promise.resolve(false);
    }
    return Promise.resolve(true);
  }

  async getAllItems(datasetId: string): Promise<DatasetInputItem[]> {
    return this.inputsResult.getValue();
  }
}

