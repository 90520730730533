import { CalculationResultItem } from './calculation-result-item';
import { DatasetInputItem } from './dataset-input-item';


export class CalculationResponse {
  file: string[] = [];
  policies: DatasetInputItem[] = [];
  sims: CalculationResultItem[] = [];
  settings: CalculationSetting[] = [];
}

export class CalculationSetting {
  parameter: string;
  value: number;
}


