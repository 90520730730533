import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { WizardComponent } from 'wre-toolkit-lib';
import { SharedService } from '../services/shared.service';
import { ErrorMessagingService } from '../components/shared/error-messaging.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {

  @ViewChild('wizard', { static: true }) wizard: WizardComponent;

  steps = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private errorMessageService: ErrorMessagingService) {

    sharedService.uploadDataset.subscribe(() => {
      this.navigateToFirstStep();
    });

    sharedService.modifyCalculation.subscribe(() => {
      this.navigateToSecondStep();
    });

    sharedService.datasetUploaded.subscribe(() => {
      this.navigateToSecondStep();
    });
  }

  private navigateToSecondStep() {
    this.router.navigate(['configuration'], { relativeTo: this.route });
    this.wizard.selectedIndex = 1;
  }

  ngOnInit() {
    this.steps = [{
      title: 'Dataset'
    },
    {
      title: 'Results'
    }];

    if (!this.sharedService.uploadedFileName) {
      this.navigateToFirstStep();
    }

    this.router.events.subscribe(
      {
        next: (event) => {
          if (event instanceof NavigationStart) {
            this.errorMessageService.clearMessage();
          }
        }
      }
    );

  }

  private navigateToFirstStep() {
    this.router.navigate(['dataset-upload'], { relativeTo: this.route });
    this.wizard.selectedIndex = 0;
  }

}
