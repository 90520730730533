import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { finalize, mergeMap } from 'rxjs/operators';
import { retryWhen, delay } from 'rxjs/operators';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {

  private urlsToRetry: string[] = [];
  private readonly retries = 2;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!req.headers.has('noretry')) {
      this.urlsToRetry.push(req.url);
    }

    const modifiedReq = req.clone({
      // avoid need to enable backend having CORS policy to allow this header
      headers: req.headers.delete('noretry')
    });

    return next.handle(modifiedReq).pipe(
      retryWhen(err => this.handleRetryOnError(err, req)),
      finalize(() => this.urlsToRetry = [...this.urlsToRetry.filter(x => x !== req.url)]));
  }

  private handleRetryOnError(errors: Observable<any>, req: HttpRequest<any>): Observable<any> {

    if (!this.urlsToRetry.includes(req.url)) {
      return throwError(() => errors);
    }

    return errors.pipe(
      mergeMap((err: HttpResponse<any>, count: number) => {
        return ((err.status !== undefined && err.status.toString().startsWith('4')) || count >= this.retries) ?
           throwError(() => err) : of(err).pipe(delay(1000));
      })
    );
  }
}
