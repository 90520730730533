<ng-container *ngIf="isNoSearchResult; then noSearchResultBlock; else ResultBlock;"></ng-container>
<ng-template #ResultBlock>
  <div #tableContainer class="table-responsive mb-0">
    <table class="table wre-table table-hover mb-0">
      <thead class="text-center" [style.left]="'-' ">
        <tr>
          <th class="id">Insured ID</th>
          <th class="businessUnit">Business Unit</th>
          <th class="policyLimit100 text-end">Policy Limit</th>
          <th class="participation text-end">Participation</th>
          <th class="attachment text-end">Attachment</th>
          <th class="policyCount text-end">Policy Count</th>
          <th class="premium text-end">Premium</th>
          <th class="usVsNon-us">Region</th>
          <th class="exposureCategory">Exposure Category</th>
          <th class="size">Size</th>
        </tr>
      </thead>
      <tbody #tableBody class="border-bottom">
        <tr *ngFor="let item of items$ | async;">
          <td class="text-nowrap id">
            <ngb-highlight [result]="item['Insured ID']" [term]="datasetsService.searchTerm"></ngb-highlight>
          </td>
          <td class="text-no-wrap businessUnit">
            <ngb-highlight [result]="item['Business Unit']" [term]="datasetsService.searchTerm"></ngb-highlight>
          </td>
          <td class="text-nowrap policyLimit100 text-end">
            <ngb-highlight [result]="item['Policy Limit']" [term]="datasetsService.searchTerm"></ngb-highlight>
          </td>
          <td class="text-nowrap participation text-end">
            <ngb-highlight [result]="item.Participation" [term]="datasetsService.searchTerm"></ngb-highlight>
          </td>
          <td class="text-nowrap attachment text-end">
            <ngb-highlight [result]="item.Attachment" [term]="datasetsService.searchTerm"></ngb-highlight>
          </td>
          <td class="text-nowrap policyCount text-end">
            <ngb-highlight [result]="item['Policy Count']" [term]="datasetsService.searchTerm"></ngb-highlight>
          </td>
          <td class="text-nowrap premium text-end">
            <ngb-highlight [result]="item.Premium" [term]="datasetsService.searchTerm"></ngb-highlight>
          </td>
          <td class="text-nowrap usVsNon-us">
            <ngb-highlight [result]="item['Region']" [term]="datasetsService.searchTerm"></ngb-highlight>
          </td>
          <td class="text-nowrap exposureCategory">
            <ngb-highlight [result]="item['Exposure Category']" [term]="datasetsService.searchTerm"></ngb-highlight>
          </td>
          <td class="text-nowrap size">
            <ngb-highlight [result]="item.Size" [term]="datasetsService.searchTerm"></ngb-highlight>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row justify-content-end mt-3">
    <div class="col-auto d-flex justify-content-end align-items-center wre-pagination">
      <label for="pageSize" class="form-control-label mb-0 me-2">Page Size</label>
      <select id="pageSize" class="form-control" name="pageSize" [(ngModel)]="datasetsService.pageSize">
        <option [value]="5">5</option>
        <option [value]="10">10</option>
        <option [value]="20">20</option>
        <option [value]="50">50</option>
      </select>
      <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="datasetsService.page" [maxSize]="5"
        [pageSize]="datasetsService.pageSize" [boundaryLinks]="true"></ngb-pagination>
    </div>
  </div>
</ng-template>
<ng-template #noSearchResultBlock>
  <div class="no-results">
    No results were found based on your search criteria. Please try again.
  </div>
</ng-template>
