import { LogLevel } from "@azure/msal-browser";

export enum Roles {
  gatewayAdmin = 'w-wre-gateway-admin',
  reportingAdmin = 'w-wre-reporting-admin',
  timeSurveyAdmin = 'w-wre-time-survey-admin',
  internalUser = 'gateway-internal-user',
  externalUser = 'gateway-external-user',
  entailUser = 'w-wre-entail-user'
}

export enum UserPermissions {
  runSimulations = 'wre-entail.sim.run',
  export = 'wre-entail.export.enable',
  useApp = 'wre-entail.useapp.view',
  GatewaySearchView = 'Gateway.Search.View',
  GatewaySlipcaseView = 'Gateway.Slipcase.View',
  GatewayAdminCogView = 'Gateway.AdminCog.View'
}

export const protectedResourceMapBase = new Map(
  [
    ['https://graph.microsoft.com/v1.0/me', ['user.read']]
  ]);

export const b2cProtectedResourceMap = new Map([]);

export const environmentBase = {
  loggingEnabled: true,
  logLevel: LogLevel.Verbose,
  authority: 'https://login.microsoftonline.com/6cacd170-f897-4b19-ac58-46a23307b80a',
  domain_hint: 'ajg0.onmicrosoft.com',
  protectedResourceMap: protectedResourceMapBase,
  applicationName: 'ajgre-entail-web',
  intranetUri: 'https://ajg0.sharepoint.com/search/Pages/results.aspx?k=',
  hideNavbarSearchExperienceForPathNames: ['dashboard'],

  rolesCacheSeconds: 10,
  authenticationCacheSeconds: 10,
  adminGroups: [Roles.gatewayAdmin, Roles.reportingAdmin],
  enableOnePlaceAuthentication: true,
  cookie: {
    enabled: true,
    scriptHtml: '//policy.cookiereports.com/71679ccd-en-gb.html'
  },
  isInternalApp: false,

  // Only used for now
  rolesConfig: {
    searchExperience: [UserPermissions.GatewaySearchView],
    slipcaseFeed: [UserPermissions.GatewaySlipcaseView],
    submitIdea: [Roles.internalUser],
    roadmaps: [Roles.internalUser],
    timeSurvey: [Roles.internalUser],
    adminArea: [UserPermissions.GatewayAdminCogView]
  },

  //B2C Config
  b2cClientId: '6e7aa1fe-2527-4ecf-aebc-434f290bd036',
  b2cAuthority: 'https://ajgb2c.b2clogin.com/ajgb2c.onmicrosoft.com/B2C_1_gre_b2c_entail_si',
  b2cResetPasswordAuthority: 'https://ajgb2c.b2clogin.com/ajgb2c.onmicrosoft.com/B2C_1_gre_b2c_entail_pass_reset',
  b2cKnownAuthorities: ['ajgb2c.b2clogin.com'],
  b2cCustomScopes: [],
  b2cProtectedResourceMap: b2cProtectedResourceMap
};
