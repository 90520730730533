<ng-container *ngIf="!pageIsLoading">
    <ng-container>
        <wre-core-header [headerConfig]="headerConfig">
        </wre-core-header>
        <main role="main">
            <wre-core-side-bar>
                <router-outlet></router-outlet>
            </wre-core-side-bar>
        </main>
        <wre-core-footer></wre-core-footer>
    </ng-container>
</ng-container>
