import { environmentBase } from './environment-base';

export const protectedResourceMapping = new Map(
  [
    ...environmentBase.protectedResourceMap,
    ['https://entailapidev.gallagherre.com/api/modelling/entail/', ['api://82851acc-fe29-478d-8c38-e3d688349580/accessuser']],
    ['https://gatewaydev.gallagherre.com/gatewayapi/api/', ['api://82851acc-fe29-478d-8c38-e3d688349580/accessuser']],
    ['https://gatewaydev.gallagherre.com/timesurveyapi/api', ['api://82851acc-fe29-478d-8c38-e3d688349580/accessuser']],
    ['https://authorizationapidev.gallagherre.com/api/', ['api://35a3b1d2-e4fe-45f6-a8d2-f6e8c2fdc48f/user_impersonation']]
  ]);

export const b2cProtectedResourceMap = new Map(
  [
    ['https://entailapidev.gallagherre.com/api/modelling/entail/', ['https://ajgb2c.onmicrosoft.com/d5b8aa63-8277-4b4e-97e7-c50b47c9b338/accessuser']],
    ['https://gatewaydev.gallagherre.com/gatewayapi/api/', ['https://ajgb2c.onmicrosoft.com/1b747f3b-a7c1-4514-9e74-f8ee3da048d8/user_impersonation']],
    ['https://gatewaydev.gallagherre.com/timesurveyapi/api', ['https://ajgb2c.onmicrosoft.com/1b747f3b-a7c1-4514-9e74-f8ee3da048d8/user_impersonation']],
    ['https://authorizationapidev.gallagherre.com/api/', ['https://ajgb2c.onmicrosoft.com/1b747f3b-a7c1-4514-9e74-f8ee3da048d8/user_impersonation']]
  ]);
export const environment = {
  ...environmentBase,
  environment: 'Dev',
  production: false,
  clientId: '6997cb3e-91fb-47d6-8782-de962404caa9',
  googleTagManagerIframeSrc:
    'https://www.googletagmanager.com/ns.html?id=GTM-NL9RW5Z&gtm_auth=zD3MnTGNY23UjBoCj4uSOQ&gtm_preview=env-3&gtm_cookies_win=x',
  gtmScriptRelativePath: '../../assets/js/googleTagManager.js',
  redirectUri: 'https://entaildev.gallagherre.com/login',
  postLogoutRedirectUri: 'https://entaildev.gallagherre.com/', // end "/" required
  appInsightsIntrumentationKey: '69ec5fcd-c299-405e-8cfb-7977bd771c87',
  timesurveyApiURI: 'https://gatewaydev.gallagherre.com/timesurveyapi/api',
  gatewayUrl: 'https://gatewaydev.gallagherre.com/',
  gatewayApiUrl: 'https://gatewaydev.gallagherre.com/gatewayapi/api/',
  searchExperienceUrl: 'http://dev.wresearch.willistowerswatson.com', // TODO: maybe not needed?
  authorisationUrl: 'https://authorizationapidev.gallagherre.com/api/',
  entailRApiUrl: 'https://entailapidev.gallagherre.com/api/modelling/entail/',
  authority: environmentBase.authority,
  domain_hint: environmentBase.domain_hint,
  rolesConfig: environmentBase.rolesConfig,
  applicationName: environmentBase.applicationName,
  protectedResourceMap: protectedResourceMapping,
  intranetUri: environmentBase.intranetUri,
  hideNavbarSearchExperienceForPathNames: environmentBase.hideNavbarSearchExperienceForPathNames,
  rolesCacheSeconds: environmentBase.rolesCacheSeconds,
  authenticationCacheSeconds: environmentBase.authenticationCacheSeconds,
  adminGroups: environmentBase.adminGroups,
  b2cProtectedResourceMap: b2cProtectedResourceMap,
  b2cClientId: '6e7aa1fe-2527-4ecf-aebc-434f290bd036'
};
