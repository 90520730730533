<ng-container *ngIf="isNoSearchResult; then noSearchResultBlock; else ResultBlock;"></ng-container>
<ng-template #ResultBlock>
  <div class="table-responsive">
    <table class="table table-hover mb-0">
      <thead>
        <tr>
          <th>Event ID</th>
          <th>Region</th>
          <th>Size</th>
          <th>Exposure Category</th>
          <th class="text-end">Sim</th>
          <th class="text-end">Event</th>
          <th class="text-end">Insured ID</th>
          <th class="text-end">Policy ID</th>
          <th>Business Unit</th>
          <th class="text-end">Gross Loss</th>
          <th class="text-end">Limit</th>
          <th class="text-end">Attachment</th>
          <th class="text-end">Insured Loss</th>
          <th class="text-end">UW Year</th>
          <th class="text-end">CY Loss</th>
        </tr>
      </thead>
      <tbody #tableBody class="border-bottom">
        <tr *ngFor="let item of items$ | async;">
          <td class="text-nowrap eventId">
            <ngb-highlight [result]="item['EVENT ID']" [term]="calculationService.searchTerm"></ngb-highlight>
          </td>
          <td class="text-no-wrap region">
            <ngb-highlight [result]="item.REGION" [term]="calculationService.searchTerm"></ngb-highlight>
          </td>
          <td class="text-nowrap size">
            <ngb-highlight [result]="item.SIZE" [term]="calculationService.searchTerm"></ngb-highlight>
          </td>
          <td class="text-nowrap exposureCategory">
            <ngb-highlight [result]="item['EXPOSURE CATEGORY']" [term]="calculationService.searchTerm"></ngb-highlight>
          </td>
          <td class="text-nowrap sim text-end">
            <ngb-highlight [result]="item.SIM" [term]="calculationService.searchTerm"></ngb-highlight>
          </td>
          <td class="text-nowrap event text-end">
            <ngb-highlight [result]="item.EVENT" [term]="calculationService.searchTerm"></ngb-highlight>
          </td>
          <td class="text-nowrap insuredUniqueId text-end">
            <ngb-highlight [result]="item['INSURED ID']" [term]="calculationService.searchTerm"></ngb-highlight>
          </td>
          <td class="text-nowrap insuredUniqueId text-end">
            <ngb-highlight [result]="item['POLICY ID']" [term]="calculationService.searchTerm"></ngb-highlight>
          </td>
          <td class="text-nowrap businessUnit">
            <ngb-highlight [result]="item['BUSINESS UNIT']" [term]="calculationService.searchTerm"></ngb-highlight>
          </td>
          <td class="text-nowrap gross text-end">
            <wre-toolkit-highlighter [result]="item['GROSS LOSS'] | number" [term]="calculationService.searchTerm"
              [ignoreResultChar]="','"></wre-toolkit-highlighter>
          </td>
          <td class="text-nowrap stackLim text-end">
            <wre-toolkit-highlighter [result]="item.LIMIT | number" [term]="calculationService.searchTerm"
              [ignoreResultChar]="','"></wre-toolkit-highlighter>
          </td>
          <td class="text-nowrap attach text-end">
            <wre-toolkit-highlighter [result]="item.ATTACHMENT | number" [term]="calculationService.searchTerm"
              [ignoreResultChar]="','"></wre-toolkit-highlighter>
          </td>
          <td class="text-nowrap net text-end">
            <wre-toolkit-highlighter [result]="item['INSURED LOSS'] | number" [term]="calculationService.searchTerm"
              [ignoreResultChar]="','"></wre-toolkit-highlighter>
          </td>
          <td class="text-nowrap net">
            <wre-toolkit-highlighter [result]="item['UW YEAR']" [term]="calculationService.searchTerm"
              [ignoreResultChar]="','"></wre-toolkit-highlighter>
          </td>
          <td class="text-nowrap net text-end">
            <wre-toolkit-highlighter [result]="item['CY LOSS'] | number" [term]="calculationService.searchTerm"
              [ignoreResultChar]="','"></wre-toolkit-highlighter>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="row justify-content-end mt-3">
    <div class="col-auto d-flex justify-content-end align-items-center wre-pagination">
      <label for="pageSize" class="form-control-label mb-0 me-2">Page Size</label>
      <select id="pageSize" name="pageSize" [(ngModel)]="calculationService.pageSize">
        <option [value]="5">5</option>
        <option [value]="10">10</option>
        <option [value]="20">20</option>
        <option [value]="50">50</option>
      </select>
      <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="calculationService.page" [maxSize]="5"
        [pageSize]="calculationService.pageSize" [boundaryLinks]="true"></ngb-pagination>
    </div>
  </div>
</ng-template>
<ng-template #noSearchResultBlock>
  <div class="no-results">
    No results were found based on your search criteria. Please try again.
  </div>
</ng-template>
