import { Component, ElementRef, OnInit } from '@angular/core';
import { SharedService } from './services/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: []
})
export class AppComponent implements OnInit {
  title = 'wre-entail-web';

  // Used to pass pageIsLoading value from shell component
  constructor(public adminService: SharedService, private _elementRef: ElementRef) { }

  ngOnInit() {

    this._elementRef.nativeElement.removeAttribute('ng-version');
  }
}
