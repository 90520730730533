import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthorisationService } from 'wre-authlib';
import { GoogleTagManagerService, WreCoreHeaderConfiguration } from 'wre-core-lib';
import { SharedService } from '../services/shared.service';

// To support Google Analytics' dataLayer object which stores custom data
declare global {
  interface Window { dataLayer: any[]; }
}


@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: []
})
export class ShellComponent implements OnInit {

  headerConfig: WreCoreHeaderConfiguration;
  pageIsLoading = true;


  constructor(
    private _authService: AuthorisationService,
    private sharedService: SharedService,
    private _googleTagManagerService: GoogleTagManagerService) {

    this.headerConfig = {
      title: 'Modelling Workspace',
      logoPath: './assets/images/logo-gateway.png',
      appName: environment.applicationName
    };
  }

  ngOnInit(): void {
    this.sharedService.pageIsLoading = false;
    this.pageIsLoading = false;

  }

  onActivate(componentReference) {
    if (componentReference.setTitle) {
      // componentReference.anyFunction(); You can call any function of the child component
      componentReference.setTitle.subscribe((data) => {
        if (data !== undefined && data !== '') {
          this.headerConfig.title = data;
        }
      });
    }
  }

}
