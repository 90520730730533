<div class="entail-toolbar tool-title btn-toolbar d-flex align-items-center">
    <h1 class="me-auto d-flex align-items-center"><span>e</span>NTAIL</h1>
</div>
<div class="wre-entail-container wre-main-container">
    <div class="row">
        <div class="col-sm-12">

            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Casualty Catastrophe Model</h3>
                </div>
                <div class="card-body">
                    <div class="wizard-step">
                        <wre-toolkit-wizard #wizard linear>
                            <cdk-step *ngFor="let step of steps; let i = index" [label]="step.title" [completed]="true">
                                <app-error-messaging></app-error-messaging>
                                <router-outlet *ngIf="i === wizard.selectedIndex"></router-outlet>
                            </cdk-step>
                        </wre-toolkit-wizard>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>