import { Component } from '@angular/core';
import { of } from 'rxjs';
import { CalculationRequest } from 'src/app/models/calculation-request';
import { CalculationResultItem } from 'src/app/models/calculation-result-item';
import { CalculationService } from 'src/app/services/calculation-service';


@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.sass']
})
export class ResultsComponent {

  get isNoSearchResult(): boolean { return this.calculationService.isNoSearchResult; }

  calculationRequest: CalculationRequest = new CalculationRequest();

  items$ = of<CalculationResultItem[]>([]);
  total$ = of<number>();

  constructor(public calculationService: CalculationService) {
    this.items$ = this.calculationService.items$;
    this.total$ = this.calculationService.total$;
  }

}
