import { Component, OnInit } from '@angular/core';
import { ErrorMessagingService } from '../error-messaging.service';

@Component({
    selector: 'app-error-messaging',
    templateUrl: './error-messaging.component.html',
    styleUrls: ['./error-messaging.component.scss']
})

export class ErrorMessagingComponent implements OnInit {

    errorMessage: string;

    constructor(private errorMessageService: ErrorMessagingService) { }

    ngOnInit(): void {
        this.errorMessageService.errorMessage.subscribe(
            {
                next: (errorStr: string) => this.errorMessage = errorStr
            }
        );
    }

}
