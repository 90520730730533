import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CalculationResponse } from '../models/calculation-respose';
import { CalculationResultItem } from '../models/calculation-result-item';
import { BaseListService } from './base-list.service';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})

export class CalculationService extends BaseListService<CalculationResultItem> {
  calculationResult = new BehaviorSubject<CalculationResultItem[]>(null);

  constructor(private http: HttpClient, private sharedService: SharedService) {
    super();
  }

  matches(item: CalculationResultItem, term: string) {
    const lowerTerm = term.toLowerCase();
    return item['EVENT ID']?.toLowerCase().includes(lowerTerm) ||
      item['REGION']?.toLowerCase().includes(lowerTerm) ||
      item['SIZE']?.toLowerCase().includes(lowerTerm) ||
      item['EXPOSURE CATEGORY']?.toLowerCase().includes(lowerTerm) ||
      item['SIM']?.toString().includes(lowerTerm) ||
      item['EVENT']?.toString().includes(lowerTerm) ||
      item['INSURED ID']?.toString().includes(lowerTerm) ||
      item['POLICY ID']?.toString().includes(lowerTerm) ||
      item['BUSINESS UNIT']?.toLowerCase().includes(lowerTerm) ||
      item['GROSS LOSS']?.toString().includes(lowerTerm.replace(/,/g, '')) ||
      item['LIMIT']?.toString().includes(lowerTerm) ||
      item['ATTACHMENT']?.toString().includes(lowerTerm) ||
      item['INSURED LOSS']?.toString().includes(lowerTerm) ||
      item['UW YEAR']?.toLowerCase().includes(lowerTerm) ||
      item['CY LOSS']?.toString().includes(lowerTerm);
  }

  setResults(result: CalculationResultItem[]) {
    this.calculationResult.next(result);
    this.loadData();

    this.sharedService.calculationCompleted.emit(true);
  }

  async runCalculation(): Promise<boolean> {
    const request = this.sharedService.request;
    request.filename = request.filename.split('//').join('%2F');

    const apiUrl = `${ environment.entailRApiUrl }sims`;
    try {

      const response = this.http.get<CalculationResponse>(apiUrl, {
        params: this.sharedService.prepareCalculationHttpParams(),
        responseType: 'json',
        observe: 'response'
      });

      const result = await firstValueFrom(response);
      console.log(result);
      if (!result) {
        return false;
      }

      this.calculationResult.next(result.body.sims);
      this.loadData();

      this.sharedService.calculationCompleted.emit(true);

      return true;

    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async getAllItems(calculationId: string): Promise<CalculationResultItem[]> {
    return this.calculationResult.getValue();
  }


}

