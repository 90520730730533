import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorMessagingService } from './components/shared/error-messaging.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private errorMessagingService: ErrorMessagingService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (req.url.startsWith(environment.entailRApiUrl)) {

            return next.handle(req)
                .pipe(
                    catchError(err => this.handleError(err, req))
                );
        }

        return next.handle(req);
    }

    private handleError(err: HttpResponse<any>, req: HttpRequest<any>) {

        console.warn(err);
        if (err.status === 400 || err.status === 422) {
            let errorMessage: string;

            if (this.isBlobError(err)) {
                const reader = new FileReader();
                reader.onloadend = (e) => {

                    try {
                        const result = JSON.parse((e.target as any).result);
                        errorMessage = result?.message;
                    } catch (exception) {
                        errorMessage = 'A server error occurred processing your request.';
                    }

                    this.errorMessagingService.setMessage(errorMessage ?? 'A server error occurred processing your request.');
                };
                reader.readAsText((err as any).error);
                return of(null);
            }

            const errorResponse = (err as any as HttpErrorResponse)?.error;
            errorMessage = errorResponse?.message;
            this.errorMessagingService.setMessage(errorMessage ?? 'A server error occurred processing your request.');

        } else {
            this.errorMessagingService.setMessage('A server error occurred processing your request.');
        }
        return of(null);
    }

    isBlobError(err: any) {
        return err instanceof HttpErrorResponse && err.error instanceof Blob;
    }
}
