import { CdkStepperModule } from '@angular/cdk/stepper';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, UrlSerializer } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { environment } from 'src/environments/environment';
import { WreAuthlibModule } from 'wre-authlib';
import { AppInsightsService, WreCoreLibModule } from 'wre-core-lib';
import { WreToolkitLibModule } from 'wre-toolkit-lib';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { DatasetUploadComponent } from './components/dataset/dataset.component';
import { InputsComponent } from './components/inputs/inputs.component';
import { ResultsComponent } from './components/results/results.component';
import { SharedModule } from './components/shared/shared.module';
import { ErrorInterceptor } from './error-interceptor';
import { HomeComponent } from './home/home.component';
import { LowerCaseUrlSerializer } from './lower-case-url-serializer';
import { RetryInterceptor } from './retry-interceptor';
import { ShellComponent } from './shell/shell.component';
import { BrowserCacheLocation } from '@azure/msal-browser';
import { MsalRedirectComponent } from '@azure/msal-angular';

const internalAuthConfig = {
  protectedResourceMap: environment.protectedResourceMap,
  auth: {
    clientId: environment.clientId,
    authority: environment.authority,
    redirectUri: environment.redirectUri,
    postLogoutRedirectUri: environment.postLogoutRedirectUri,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
  homePageUrl: '/dashboard',
  unauthorisedPageUrl: '/unauthorized',
  applicationName: environment.applicationName,
  cacheTimeInSeconds: environment.rolesCacheSeconds,
  authenticationCacheSeconds: environment.authenticationCacheSeconds,
  authorisationUrl: environment.authorisationUrl,
};

const externalAuthConfig = {
  protectedResourceMap: environment.b2cProtectedResourceMap,
  customScopes: environment.b2cCustomScopes,
  auth: {
    clientId: environment.b2cClientId,
    authority: environment.b2cAuthority,
    redirectUri: environment.redirectUri,
    postLogoutRedirectUri: environment.postLogoutRedirectUri,
    knownAuthorities: environment.b2cKnownAuthorities,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
  homePageUrl: '/dashboard',
  unauthorisedPageUrl: '/unauthorized',
  applicationName: environment.applicationName,
  cacheTimeInSeconds: environment.rolesCacheSeconds,
  authenticationCacheSeconds: environment.authenticationCacheSeconds,
  authorisationUrl: environment.authorisationUrl,
};

@NgModule({
  declarations: [
    AppComponent,
    ShellComponent,
    HomeComponent,
    DatasetUploadComponent,
    ConfigurationComponent,
    InputsComponent,
    ResultsComponent
  ],
  imports: [
    WreAuthlibModule.forRoot(internalAuthConfig, externalAuthConfig, environment.loggingEnabled, environment.logLevel),
    WreCoreLibModule.forRoot(
      environment.gatewayUrl,
      environment.gatewayApiUrl,
      environment.appInsightsIntrumentationKey,
      environment.applicationName,
      environment.timesurveyApiURI,
      environment.intranetUri,
      environment.hideNavbarSearchExperienceForPathNames,
      environment.adminGroups,
      environment.applicationName,
      environment.rolesConfig,
      environment.googleTagManagerIframeSrc,
      environment.gtmScriptRelativePath,
      environment.cookie,
      environment.isInternalApp
    ),
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    TourMatMenuModule,
    WreToolkitLibModule,
    CdkStepperModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  providers: [
    AppInsightsService,
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}
