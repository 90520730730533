import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ErrorMessagingService {
    constructor() { }
    private errorMessageSource = new BehaviorSubject<string>(null);

    errorMessage = this.errorMessageSource.asObservable();

    setMessage(message: string) {
        this.errorMessageSource.next(message);
    }

    clearMessage() {
        this.errorMessageSource.next(null);
    }

}
