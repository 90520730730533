<div class="wizard-grid" [ngClass]="{'uploadError': fileUploadError }">
    <div class="wizard-grid-inner d-flex align-items-center justify-content-center">

        <div class="p-3" tourAnchor="upload-template.anchor">
            <div class="text-center" *ngIf="!isLoading">
                <strong>{{infoText}}</strong>
                <div class="file-desc">
                    <p>Accepted file Types: .xlsx</p>
                    <p>Max file size: 20MB</p>
                </div>
                <div>
                    <input #fileUpload type="file" class="file-select" (change)="fileChange($event)" placeholder="Upload file" accept=".xlsx">
                    <button class="btn btn-primary mt-2" role="button" type="button" (click)="fileUpload.click();">Select a file</button>
                </div>
            </div>
        </div>

        <app-loading [isLoading]="isLoading"></app-loading>
    </div>

    <div tourAnchor="download-template.anchor" class="card-footer template-file border-top">
        <p><i class="far fa-info-square me-2"></i>To download an example dataset template file, please <a href="javascript:" (click)="downloadTemplateFile()">click here</a>.</p>
    </div>
</div>
