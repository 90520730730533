import { Component } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import { DatasetInputsService } from 'src/app/services/dataset-inputs.service';
import { ErrorMessagingService } from '../shared/error-messaging.service';

@Component({
  selector: 'app-dataset',
  templateUrl: './dataset.component.html',
  styleUrls: ['./dataset.component.sass']
})
export class DatasetUploadComponent {

  infoText = 'Please upload a dataset...';
  fileToUpload: File = null;

  isLoading = false;

  private readonly fileUploadMaxSize: number = 20; // in MB

  fileUploadError = false;
  errorMessages = {
    MaxSizeReached: 'Upload File Size cannot be greater than 20 MB.',
    IncorrectFile: 'Uploaded file can only be an Excel Spreadsheet.'
  };

  private uploadCompleted = false;

  constructor(
    private datasetsService: DatasetInputsService,
    private sharedService: SharedService,
    private errorMessagingService: ErrorMessagingService
  ) {
    console.log('In DatasetComponent:', sharedService.request.files);
  }

  downloadTemplateFile() {
    this.sharedService.downloadTemplate();
  }

  uploaded() {
    this.uploadCompleted = true;
    this.sharedService.datasetUploaded.emit(true);
  }

  get nextButtonDisabled() {
    this.infoText = 'Please upload a dataset...';

    return !this.uploadCompleted;
  }

  async fileChange(event) {

    this.errorMessagingService.clearMessage();
    this.fileUploadError = false;

    if (!this.fileIsValid(event)) {
      return;
    }

    this.sharedService.request.files = event.target.files;
    this.fileToUpload = this.sharedService.request.files.item(0);
    if (this.sharedService.request.files.length > 0) {
      console.log('In DatasetComponent:fileChange:', this.fileToUpload);

      this.isLoading = true;
      const result = await this.datasetsService.getData(this.fileToUpload);
      this.isLoading = false;

      if (!result) {
        this.fileUploadError = true;
        return;
      }

      this.sharedService.uploadedFileName = this.infoText;
      this.uploaded();
    }
  }

  private fileIsValid(event): boolean {
    const selectedFile = event.target.files[0];
    this.infoText = selectedFile.name;

    if (!(selectedFile.name as string).endsWith('xlsx')) {
      this.setErrorMessage(event, this.errorMessages.IncorrectFile);
      return false;
    }

    if (this.getFileSizeInMB(selectedFile.size) > this.fileUploadMaxSize) {
      this.setErrorMessage(event, this.errorMessages.MaxSizeReached);
      return false;
    }

    return true;
  }

  private getFileSizeInMB(selectedFileSizeInBytes): number {
    return selectedFileSizeInBytes / Math.pow(1024, 2);
  }

  private setErrorMessage(event: Event, errorMsg: string) {
    const target = (event.target as HTMLInputElement);
    this.errorMessagingService.setMessage(errorMsg);
    target.value = '';
    this.fileUploadError = true;
  }

}
