import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CalculationRequest } from 'src/app/models/calculation-request';
import { WreToolkitLibModule } from 'wre-toolkit-lib';

import { LoadingComponent } from './loading/loading.component';
import { ErrorMessagingComponent } from './error-messaging/error-messaging.component';
import { ErrorMessagingService } from './error-messaging.service';



@NgModule({
    declarations: [
        LoadingComponent,
        ErrorMessagingComponent
    ],
    imports: [
        CommonModule,
        WreToolkitLibModule
    ],
    exports: [CommonModule, LoadingComponent, ErrorMessagingComponent],
    providers: [CalculationRequest, ErrorMessagingService]
})
export class SharedModule { }
