import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CalculationRequest } from '../models/calculation-request';
import { CalculationResponse } from '../models/calculation-respose';
import { saveAs } from 'file-saver';
import { firstValueFrom } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class SharedService {
    pageIsLoading = true;

    uploadDataset = new EventEmitter<boolean>();

    datasetUploaded = new EventEmitter<boolean>();

    calculationCompleted = new EventEmitter<boolean>();
    modifyCalculation = new EventEmitter<boolean>();

    request: CalculationRequest = new CalculationRequest();
    uploadResponse: CalculationResponse = new CalculationResponse();
    uploadedFileName: string;


    constructor(private http: HttpClient) { }

    async downloadTemplate(): Promise<any> {
        return await firstValueFrom(this.http.get(`${ environment.entailRApiUrl }download_template`, { responseType: 'blob' }))
            .then((response: Blob) => saveAs(response, 'data_input_template.xlsx'));
    }

    async downloadiFM(): Promise<any> {
        return await firstValueFrom(this.http.post(`${ environment.entailRApiUrl }download_ifm`, null,
            { responseType: 'blob', params: this.prepareCalculationHttpParams() }))
            .then((response: Blob) => saveAs(response, `data_ifm_${ new Date() }.csv`));
    }

    async downloadOutput(): Promise<any> {
        return await firstValueFrom(this.http.post(`${ environment.entailRApiUrl }download_sims`, null,
            { responseType: 'blob', params: this.prepareCalculationHttpParams() }))
            .then((response: Blob) => saveAs(response, `data_sims_${ new Date() }.csv`));
    }

    prepareCalculationHttpParams() {
        return new HttpParams()
            .append('seed', String(this.request.seed))
            .append('n', String(this.request.simulations))
            .append('mean', String(this.request.mean))
            .append('cv', String(this.request.cv))
            .append('uncertainty', this.request.uncertainty ? '1' : '0')
            .append('file', this.request.filename);
    }
}

