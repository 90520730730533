import { Injectable } from '@angular/core';

@Injectable()
export class CalculationRequest {

    filename = '';

    files: FileList;

    simulations = 0;

    seed = 0;

    mean = 0;

    cv = 0;

    uncertainty = true;
}

