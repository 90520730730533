<div class="wizard-area border-bottom">
    <form *ngIf="form" [formGroup]="form">
        <div class="sim-details">
            <div class="row">
                <div class="col-auto ms-auto order-lg-2">
                    <div class="d-flex flex-row navigation">
                        <div class="clickable-icon">
                            <button class="btn btn-secondary me-3" role="button" (click)="goBack()" type="button"><i class="far fa-long-arrow-alt-left me-2"></i>Back</button>
                        </div>
                        <div class="clickable-icon border-start">
                            <button class="btn btn-success ms-3" role="button" [disabled]="runButtonDisabled" (click)="runCalculation()" type="button"><i class="far fa-sync-alt me-2"></i>Rerun</button>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg order-lg-1 mt-2 mt-lg-0">
                    <div class="clickable-icon dataset-details">
                        <label class="form-control-label me-2 mb-0 mt-0">Dataset:</label>
                        <strong class="dataset-name me-3" wreToolkitTitleWhenTruncated ngbTooltip container="body">{{sharedService.uploadedFileName}}</strong>
                    </div>
                </div>
            </div>
            <div class="sim-config mb-0 mb-lg-3">
                <div class="row">
                    <div class="col-6 col-lg-auto">
                        <div class="d-flex mb-0 ms-0">
                            <label for="inputEmail3" class="form-control-label mb-0 me-2">Seed:</label>
                            <div class="col">
                                <input type="number" class="form-control" id="seed" formControlName="seed">
                                <div *ngIf="form.controls['seed'].errors?.min || form.controls['seed'].errors?.max" class="error pt-2">
                                    Range: 1 - 9999999
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-auto">
                        <div class="d-flex mb-0 ms-0">
                            <label for="inputEmail3" class="form-control-label mb-0 me-2">Simulation:</label>
                            <div class="col">
                                <input type="number" class="form-control" id="simulation" formControlName="simulation">
                                <div *ngIf="form.controls['simulation'].errors?.min || form.controls['simulation'].errors?.max" class="error pt-2">
                                    Range: 1 - 9999999
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-auto mt-3 mt-lg-0">
                        <div class="d-flex mb-0 ms-0">
                            <label for="inputEmail3" class="form-control-label mb-0 me-2">Mean:</label>
                            <div class="col">
                                <input type="number" class="form-control" id="mean" formControlName="mean">
                                <div *ngIf="form.controls['mean'].errors?.min || form.controls['mean'].errors?.max" class="error pt-2">
                                    Range: 1 - 9999999
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-auto mt-3 mt-lg-0">
                        <div class="d-flex mb-0 ms-0">
                            <label for="inputEmail3" class="form-control-label mb-0 me-2">CV:</label>
                            <div class="col">
                                <input type="number" class="form-control" id="cv" formControlName="cv">
                                <div *ngIf="form.controls['cv'].errors?.min || form.controls['cv'].errors?.max" class="error pt-2">
                                    Range: 0 - 9999999
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-auto mt-3 mt-lg-0 mb-3 mb-lg-0">
                        <div class="d-flex d-flex align-items-center mb-0 ms-0 secondary-uncertainty">
                            <wre-toolkit-checkbox-form-control class="me-3" label="Secondary Uncertainty" [control]="form.get('useSecondary')"></wre-toolkit-checkbox-form-control>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </form>
</div>

<div class="wizard-grid">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs">
        <li [ngbNavItem]="1">
            <a ngbNavLink>Input</a>
            <ng-template ngbNavContent>
                <app-loading [isLoading]="isLoading"></app-loading>
                <app-inputs *ngIf="!isLoading && activeTab === 1"></app-inputs>
            </ng-template>
        </li>
        <li [ngbNavItem]="2">
            <a ngbNavLink>Output</a>
            <ng-template ngbNavContent>
                <app-loading [isLoading]="isLoading"></app-loading>
                <app-results *ngIf="!isLoading && activeTab === 2"></app-results>
            </ng-template>
        </li>
        <li class="ms-auto search">
            <div class="d-flex">
                <div class="clickable-icon border-end me-3">
                    <div tourAnchor="export-results.anchor" class="dropdown export me-3">
                        <button [disabled]="!(canExport | async)" class="btn wre-btn btn-text dropdown-toggle pe-1 ps-2" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="far fa-file-download me-2"></i>Export<span class="caret"></span>
                        </button>
                        <div class="dropdown-menu">
                            <a (click)="export('iFM')" class="dropdown-item">
                                iFM
                            </a>
                            <a (click)="export('Output')" class="dropdown-item">
                                Output
                            </a>
                        </div>
                    </div>
                </div>
                <div class="search-filter">
                    <div *ngIf="activeTab === 1" class="input-group prepend">
                      <div>
                          <span class="input-group-text" id="basic-addon2"><i class="far fa-search"></i></span>
                      </div>
                        <input class="form-control search" id="inputsSearchText" maxlength="50" name="searchTerm" [(ngModel)]="datasetsService.searchTerm" autocomplete="off" placeholder="Search...">
                    </div>
                    <div *ngIf="activeTab === 2" class="input-group prepend">
                      <div>
                        <span class="input-group-text" id="basic-addon2"><i class="far fa-search"></i></span>
                      </div>
                      <input class="form-control search" id="resultsSearchText" maxlength="50" name="searchTerm" [(ngModel)]="calculationResultsService.searchTerm" autocomplete="off" placeholder="Search...">
                    </div>
                </div>
            </div>
        </li>
    </ul>

    <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
